import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import {
    getTaxList,
    getUnitList,
    getCustomersList,
} from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";
import { InvSales } from "../../../core/json/invSales";

import {
    addEditSales,
    getbillwisepayment,
    getSalesByTransType,
} from "../../../services/SalesApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
    fetchUnit,
    fetchProduct,
    fetchTax,
    fetchSettings,
    fetchCustomer,
} from "../../../redux/userSetting";
import CustomerModal from "../../master/modal/CustomerModal";
const dummydata=[
    {
        billtype:'p',
        billno:'1',
        date: new Date(),
        billamount: 2500,
        paid: 100,
        discount: 2,
        inNo:'i1',
        inDate: new Date()
    },
    {
        billtype:'p',
        billno:'2',
        date: new Date(),
        billamount: 3000,
        paid: 250,
        discount: 20,
        inNo:'i2',
        inDate: new Date()
    },
    {
        billtype:'p',
        billno:'3',
        date: new Date(),
        billamount: 400,
        paid: 10,
        discount: 0,
        inNo:'i3',
        inDate: new Date()
    },
];
const Billpaymentmodal = ({ mode, data, handleClose, handleRefresh }) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            deliveryDate: dayjs().format("YYYY-MM-DD"),
            invdate: dayjs().format("YYYY-MM-DD"),
            isCheque: false,
            amount: 0,
          
        },
        mode: "onBlur",
    });
    const isCheque = watch('isCheque');
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });

    const dispatch = useDispatch();

    const [taxData, setTaxData] = useState([]);

    const [unitData, setunitData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [salesData, setsalesData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isRowAdded, setIsRowAdded] = useState(false);
    const [decimalpoints, setDecimalPoints] = useState(2);
    const {
        productList,
        unitList,
        settingList,
        customerList,
        formatedproductList,
    } = useSelector((state) => state.usersetting);
useEffect(() => {
  
        // Map the productBOQDetails if available
        const data = dummydata?.map((e) => {
          let bal = e.billamount- (e.paid+ e.discount);
      
          // Return the object properly
          return {
        
              billtype: e.billtype,
              billno: e.billno,
              date: e.date,
              billamount: e.billamount,
              paid: e.paid,
              discount: e.discount,
              inNo: e.inNo,
              indate: e.inDate,
              balance: bal,
              // Rounds total cost to 2 decimal places
          };
      });
      
        setValue('items', data)
 
}, [])

    const paymentType = [
        { value: 0, label: "Cash" },
        { value: 1, label: "Credit" },
        { value: 2, label: "UPI" },
        { value: 3, label: "Card" },
    ];

    const salesMode = [
        { value: 0, label: "NORMAL SERIES" },
        { value: 1, label: "QUOT SERIES" },
    ];
    const { userId } = useSelector((state) => state.userauth);

    useEffect(() => {
        dispatch(fetchUnit());
        dispatch(fetchProduct(branch));
        dispatch(fetchTax());
        dispatch(fetchSettings(branch));

        const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
        const currfmt = match ? match[0]?.length - 1 : 0;
        setDecimalPoints(currfmt);
    }, []);

    //Calculations ---------------



    const handleUnitList = async () => {
        try {
            const response = await getUnitList();
            const formattedUnit = response?.map((unit) => ({
                label: unit.name,
                value: unit.guid,
            }));
            setunitData(formattedUnit);
        } catch (error) {
            console.error(
                "Error fetching unit",
                error?.response?.data?.Message || "something went wrong"
            );
        }
    };

    const handleTaxList = async () => {
        try {
            const response = await getTaxList();

            const formattedTax = response?.map((tax) => ({
                label: tax.percentage,
                value: tax.percentage,
                guid: tax.guid,
            }));
            setTaxData(formattedTax);
        } catch (error) {
            console.error(
                "Error fetching tax",
                error?.response?.data?.Message || "something went wrong"
            );
        }
    };



    const handleCustomerList = async () => {
        try {
            // const response = await getCustomersList(branch);
            const formattedCustomers = customerList?.map((customer) => ({
                label: customer.name,
                value: customer.guid,
            }));
            setCustomerData(formattedCustomers);
        } catch (error) {
            console.error(
                "Error fetching customers",
                error?.response?.data?.Message || "Unexpected error occured"
            );
        }
    };

    const handlesalesData = async () => {
        try {
            const response = await getSalesByTransType(branch, 0);
            setsalesData(response.data);
        } catch (error) {
            console.error(
                "Failed to fetch sales quotations",
                error?.response?.data?.Message || "something went wrong"
            );
        }
    };

    // Get branch from redux
    const { branch } = useSelector((state) => state.branchlist);
    // Fetch sales quotations data
    const handleEntryNo = async () => {
        try {
            const response = await getbillwisepayment(branch);
            const salesData = response?.data || [];

            // Group entry numbers by prefix
            const entryGroups = salesData.reduce((groups, data) => {
                const entrynumber = data.entrynumber;
                // Extract the prefix (non-numeric characters)
                const prefix = entrynumber.match(/^[A-Za-z]+/)[0];
                // Extract the numeric part and convert it to an integer
                const entryNum = parseInt(entrynumber.replace(/\D/g, ""), 10);

                if (!groups[prefix]) {
                    groups[prefix] = [];
                }
                groups[prefix].push(entryNum);

                return groups;
            }, {});

            // Get the desired prefix (e.g., "SQ")
            const prefix = "BWP";
            const maxEntryNumber =
                entryGroups[prefix]?.length > 0 ? Math.max(...entryGroups[prefix]) : 0;

            // Increment the counter for the prefix
            const counter = maxEntryNumber + 1;
console.log(maxEntryNumber,"mmmm");

            // Set the new entry number with the updated counter
            setValue(`entrynumber`, `${prefix}${counter}`);
        } catch (error) {
            console.error(
                "Failed to fetch sales invoices",
                error?.response?.data?.Message || "something went wrong"
            );
            setValue(`entrynumber`, `BWP1`);
            
        }
    };

    function resetmodal() {
        if (mode === "edit" && data) {
            setValue("terms", data.terms);
            setValue("entrynumber", data.entrynumber);
            console.log(data.salesTransType);
            setValue("salesTransType", data.salesTransType || 0);
            setValue("addition", data.additionalAmt || 0);
            setValue("subtraction", data.discAmt || 0);
            setValue("round", data.roundOff || 0);
            setValue("grossTotal", data.grossTotal || 0);
            setValue("taxTotal", data.taxTotal || 0);
            setValue("netAmt", data.netAmt || 0);
            setValue("paymentType", data.salesPaymentType || 0);
            console.log("data.customer.guid", data.customer.guid);

            setValue("customer", data.customer.guid);
            setValue("invdate", dayjs(data.invdate) || dayjs());
            setValue("deliveryDate", dayjs(data.deliveryDate) || dayjs());
        }
        data?.invSalesDetails?.map((detail, i) => {
            console.log(detail?.product?.guid, "-----------detail");
        });

      
    }
    console.log(getValues().items);
    useEffect(() => {
        resetmodal();
    }, [data, append, remove]);

    useEffect(() => {
        handleCustomerList();
    }, [customerList]);

    useEffect(() => {
        const fetchData = async () => {
            if (mode === "add") {
                handleEntryNo();
                if (!isRowAdded && fields?.length === 0) {
               
                    setIsRowAdded(true);
                }
            }

            handleTaxList();
            handleUnitList();
            handlesalesData();
        };

        fetchData();
    }, []);

    async function getUnitByGuid(guid) {
        const unitData = await getUnitList();
        const filteredData = unitData.filter((unit) => unit.guid === guid);
        return filteredData[0];
    }

    async function getTaxByGuid(guid) {
        const taxData = await getTaxList();
        const filteredData = taxData.filter((tax) => tax.guid === guid);
        return filteredData[0];
    }

    async function getProductByGuid(guid) {
        const filteredData = productList?.filter(
            (product) => product.guid === guid
        );
        console.log("filteredData[0]", filteredData[0]);
        return filteredData[0];
    }
 
  
// const amount = watch().amount || 0;
    return (
        <div>
            <div
                className="modal fade show"
                style={{ display: "block" }}
                tabIndex="-1"
            >
                <div className="modal-dialog add-centered">
                    <div className="modal-content">
                        <div className="page-wrapper p-0 m-0">
                            <div className="content p-0">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>
                                            {" "}
                                            {mode === "edit"
                                                ? "Edit Bill Wise Payment"
                                                : "Add Bill Wise Payment"}
                                        </h4>
                                    </div>
                                    <button type="button" className="close" onClick={handleClose}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <form >
                                            <div className="row">
                                                {/* row1 */}
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <label className="form-label">Entry No.</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("entrynumber")}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <label>Date</label>
                                                        <div className="input-groupicon calender-input">
                                                            <Calendar className="info-img" />
                                                            <Controller
                                                                control={control}
                                                                name="paymentdate"
                                                                render={({ field: { onChange, value } }) => (
                                                                    <DatePicker
                                                                        className="datetimepicker"
                                                                        value={value ? dayjs(value) : dayjs()}
                                                                        format="DD-MM-YYYY"
                                                                        onChange={(date) =>
                                                                            onChange(
                                                                                date ? date.format("YYYY-MM-DD") : null
                                                                            )
                                                                        } // Update on change
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks add-product">
                                                        <label>
                                                            Supplier<span className="text-danger">*</span>
                                                        </label>

                                                        <Controller
                                                            control={control}
                                                            name={`supplier`}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Select
                                                                    classNamePrefix="react-select"
                                                                    options={customerData}
                                                                    value={customerData?.find(
                                                                        (customer) => customer?.value === value
                                                                    )}
                                                                    onChange={(selectedOption) =>
                                                                        onChange(selectedOption.value)
                                                                    }
                                                                    styles={{
                                                                        menu: (provided) => ({
                                                                            ...provided,
                                                                            zIndex: 9999,
                                                                        }),
                                                                    }}
                                                                    required
                                                                />
                                                            )}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <label className="form-label">
                                                            Ledger<span className="text-danger">*</span>
                                                        </label>
                                                        <div className="row">
                                                            <Controller
                                                                control={control}
                                                                name={`ledger`}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        options={paymentType}
                                                                        value={paymentType?.find(
                                                                            (paymentType) =>
                                                                                paymentType?.value === value
                                                                        )}
                                                                        onChange={(selectedOption) =>
                                                                            onChange(selectedOption.value)
                                                                        }
                                                                        styles={{
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 9999,
                                                                            }),
                                                                        }}
                                                                        required
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <div className="form-check form-switch mt-4">
                                                            <input className="form-check-input" type="checkbox" {...register("isCheque")} />
                                                            <label className="form-check-label">Cheque ?</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isCheque && (
                                                    <>
                                                        {['chequeNo', 'accNo', 'holdername'].map((field, index) => (
                                                            <div key={index} className="col-lg-4 col-sm-6 col-12">
                                                                <div className="input-blocks">
                                                                    <label className="form-label">{`${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}`}</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        {...register(field)}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div className="col-lg-4 col-sm-6 col-12">
                                                            <div className="input-blocks">
                                                                <label>Cheque Date</label>
                                                                <div className="input-groupicon calender-input">
                                                                    <Calendar className="info-img" />
                                                                    <Controller
                                                                        control={control}
                                                                        name="chequedate"
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <DatePicker
                                                                                className="datetimepicker"
                                                                                value={value ? dayjs(value) : dayjs()}
                                                                                format="DD-MM-YYYY"
                                                                                onChange={(date) =>
                                                                                    onChange(date ? date.format("YYYY-MM-DD") : null)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}


                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2 col-sm-6 col-6">
                                                    <div className="input-blocks">
                                                        <label className="form-label">Amount</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("amount")}
                                                            required

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="input-blocks mt-1">
                                                        {/* <label className="form-label">Amount</label> */}
                                                        <button className="btn btn-success mt-4 px-4">Fill</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive  no-pagination table-container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>

                                                            <th className="sticky-header"> </th>
                                                            <th className="sticky-header">Bill Type</th>
                                                            <th className="sticky-header">Bill No.</th>
                                                            <th className="sticky-header">Date</th>
                                                            <th className="sticky-header">Bill Amount</th>
                                                            <th className="sticky-header">Paid</th>
                                                            <th className="sticky-header">Paying</th>
                                                            <th className="sticky-header">Discount</th>
                                                            <th className="sticky-header">Balance</th>
                                                            <th className="sticky-header">Sup Inv No.</th>
                                                            <th className="sticky-header">Sup Inv Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fields?.map((obj, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input type="checkbox"  {...register(`items[${index}].select`)} />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].billtype`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].billno`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].date`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].billamount`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].paid`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].paying`)}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].discount`)}

                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].balance`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].invNo`)}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].invdate`)}
                                                                            disabled
                                                                        />
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })}
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>Amount</td>
                                                            <td>paid</td>
                                                            <td>paying</td>
                                                            <td>discount</td>
                                                            <td>balance</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <div className="input-blocks">
                                                        <label className="form-label">Remarks</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("remark")}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">

                                                <div className="col-lg-12 text-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-cancel add-cancel me-3"
                                                        onClick={resetmodal}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-submit add-sale"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen && (
                <CustomerModal
                    mode="add"
                    data={{}}
                    handleClose={() => setModalOpen(false)}
                    handleRefresh={() => { }}
                />
            )}
        </div>
    );
};
export default Billpaymentmodal;

