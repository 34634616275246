export const all_routes = {
  billwisepayment:'/billwisepayment',
  billwisereceipt:'/billwisereceipt',
  journal:'/journal',
  Stockout:'//Reports/Stockout',
  Stocksummary:'/Reports/Summary',
  stockin:'/Reports/StockIn',
  ledger:'/Accounts/Ledger',
  receiptmaster:'/Accounts/Receipt',
  paymentmaster:"/Accounts/Payment",
  billwisereceiptmaster:'/billwisereceiptmaster',
  journalmaster:'/journalmaster',
  openingstock:'/Inventory/OpeningStock',
  renew: "/renew",
  salesitemwise: "/sales-itemwise",
  dashboard: "/Home/Dashboard",
  branchdashboard: "/Home/BranchDashboard",
  tax: "/Masters/Tax/Index",
  couponcode: "/Masters/CouponCode/Index",
  accounttype: "/Masters/AccountsType/Index",
  currency: "/Masters/Currency/Index",
  type: "/Masters/Type/Index",
  unit: "/Masters/Unit/Index",
  reason: "/Masters/Reason/Index",
  product: "/Masters/Product/Index",
  producttype: "/Masters/ProductType/Index",
  section: "/Masters/Section/Index",
  supplier: "/Masters/Supplier/Index",
  productboq: "/Masters/ProductBOQ/Index",
  paymentcard: "/Masters/PaymentCard/Index",
  reasontype: "/Masters/ReasonType/Index",
  promotions: "/Masters/Promotion/Index",
  promoproducts: "/Masters/PromoProducts/Index",
  counter: "/Masters/Counter/Index",
  modifiercategory: "/Masters/ModifierCategory/Index",
  modifier: "/Masters/Modifiers/Index",
  kotprinter: "/Masters/KOTPrinter/Index",
  groupentry: "/Masters/GroupEntry/Index",
  discount: "/Masters/Discount/Index",
  customer: "/Masters/Customer/Index",
  category: "/Masters/Category/Index",

  //Transactions
  PurchaseInvoice: "/Transactions/Purchase/Index",
  PurchaseOrder: "/Transactions/PurchaseOrder/Index",
  GoodsReceipt: "/Transactions/GoodsReceipt/Index",
  PurchaseReturn: "/Transactions/PurchaseReturn/Index",
  SalesQuotation: "/Transactions/SalesQuotation/Index",
  DeliveryNote: "/Transactions/DeliveryNote/Index",
  SalesInvoice: "/Transactions/SalesInvoice/Index",
  SalesReturn: "/Transactions/SalesReturn/Index",
  StockAdjustment: "/Transactions/StockAdjustment/Index",
  StockTransfer: "/Transactions/StockTransfer/Index",
  StockProduct: "/Transactions/StockProduct/Index",
  Production: "/Transactions/Production/Index",
  purchasetransaction: "/Reports/Purchase",

  //administartion
  company: "/Administration/Company/Index",
  branch: "/Administration/Branch/Index",
  productimport: "/Administration/ProductImport/Index",
  bulkedit: "/Masters/Product/BulkPriceEditing",
  user: "/Administration/User/Index",
  userRole: "/Administration/UserRole/Index",
  aboutus: "/Administration/AboutUs/Index",
  //report
  //sales report
  reportsales: "/Reports/SalesReport/Index",
  itemwisesale: "/Reports/ItemWiseSalesReport/Index",
  salesaudit: "/Reports/SalesAuditReport/Index",
  cancelledsales: "/Reports/CancelReport/Index",
  cardsales: "/Reports/CardReport/Index",
  deletedsales: "/Reports/DeletedReport/Index",
  mergedsales: "/Reports/MergedSalesReport/Index",
  refundedsales: "/Reports/RefundReport/Index",
  waiterwisesales: "/Reports/WaiterWiseSalesReport/Index",
  //profit report
  categorywise: "/Reports/CategoryWiseProfitReport/Index",
  datewiseprofit: "/Reports/DateWiseProfitReport/Index",
  groupwiseprofit: "/Reports/GroupWiseProfitReport/Index",
  itemwiseprofit: "/Reports/ItemWiseProfitReport/Index",
  //summary report
  salesitemsummary: "/Reports/SalesItemSummaryReport/Index",
  wastagesummary: "/Reports/WastageReportSummary/Index",
  //report
  dailyclosereport: "/Reports/DailyCloseSalesTypeReport/Index",
  productpricereport: "/Reports/ProductPriceReport/Index",
  vatreport: "/Reports/VatReport/Index",
  payinoutreport: "/Reports/PayInOutReport/Index",
  customercredit: "/Reports/CustomerCreditReport/Index",
  customerrepayment: "/Reports/CustomerCreditRepaymentReport/Index",
  shiftclose: "/Reports/ShiftCloseReport/Index",
  wastagedetail: "/Reports/WastageReportDetail/Index",
  preoversalereport: "/Reports/PreOverStock/Index",
  addonsalereport: "/Reports/AddOnSalesReport/Index",
  stockproductreport: "/Reports/ProductStockReport/Index",
  stockoverreport: "/Reports/ProductStockOverReport/Index",
  gstreport: "/Reports/GstReport/Index",

  sectionreport: "/Reports/Consolidated/Section/Index",
  branchreport: "/Reports/Consolidated/Branch/Index",
  itemsreport: "/Reports/Consolidated/Item",
  paymodereport: "/Reports/Consolidated/PayMode",
  hourlysales: "/Reports/HourlyReportSummary/Index",
  itemqnty: "/Reports/Consol/Item",

  //................

  productlist: "/product-list",
  addproduct: "/add-product",
  createproduct: "/createproduct",
  createproducttype: "/createproducttype",
  createpaymentcard: "/createpaymentcard",
  createreason: "/createreason",
  createreasontype: "/createreasontype",
  createsupplier: "/createsupplier",
  createsection: "/createsection",
  // editpaymentcard:"/editpaymentcard",
  // editproducttype:"/editproducttype",
  // editproduct:"/editproduct",
  // editsection:"/section",
  // editsupplier:"/editsupplier",
  // editreason:"/editreason",
  // editreasontype:"/editreasontype",

  selectbranch: "/selectbranch",
  brandlist: "/brand-list",
  units: "/units",
  variantyattributes: "/variant-attributes",
  warranty: "/warranty",
  barcode: "/barcode",
  alerts: "/ui-alerts",
  accordion: "/ui-accordion",
  avatar: "/ui-avatar",
  badges: "/ui-badges",
  borders: "/ui-borders",
  buttons: "ui-buttons",
  buttonsgroup: "/ui-buttons-group",
  breadcrumb: "/ui-breadcrumb",
  cards: "/ui-cards",
  dropdowns: "/ui-dropdowns",
  colors: "/ui-colors",
  carousel: "/ui-carousel",
  navtabs: "/ui-nav-tabs",
  grid: "/ui-grid",
  images: "/ui-images",
  lightbox: "/ui-lightbox",
  media: "/ui-media",
  modals: "/ui-modals",
  offcanvas: "/ui-offcanvas",
  pagination: "/ui-pagination",
  typography: "/ui-typography",
  tooltip: "/ui-tooltips",
  draganddrop: "/ui-drag-drop",
  departmentgrid: "/department-grid",
  departmentlist: "/department-list",
  designation: "/designation",
  shift: "/shift",
  attendanceemployee: "/attendance-employee",
  clipboard: "/ui-clipboard",
  popover: "/ui-popovers",
  tablebasic: "/tables-basic",
  datatable: "/data-tables",
  basicinput: "/form-basic-inputs",
  checkboxradio: "/form-checkbox-radios",
  inputgroup: "/form-input-groups",
  gridgutters: "/form-grid-gutters",
  formselect: "/form-select",
  fileupload: "/form-fileupload",
  formmask: "/form-mask",
  formhorizontal: "/form-horizontal",
  formvertical: "/form-vertical",
  floatinglabel: "/form-floating-labels",
  formvalidation: "/form-validation",
  select2: "/form-select2",

  toasts: "/ui-toasts",
  video: "/ui-video",
  sweetalerts: "/ui-sweetalerts",
  spinner: "/ui-spinner",
  progress: "/ui-progress",
  placeholder: "/ui-placeholders",
  rating: "/ui-rating",
  texteditor: "/ui-text-editor",
  // counter: "/ui-counter",
  scrollbar: "/ui-scrollbar",
  stickynote: "/ui-stickynote",
  timeline: "/ui-timeline",
  apexchart: "/chart-apex",
  chartjs: "/chart-js",
  rangeslider: "/ui-rangeslider",
  fontawesome: "/icon-fontawesome",
  feathericon: "/icon-feather",
  ionicicons: "/icon-ionic",
  materialicons: "/icon-material",
  pe7icons: "/icon-pe7",
  simpleline: "/icon-simpleline",
  themifyicons: "/icon-themify",
  iconweather: "/icon-weather",
  typicons: "/icon-typicon",
  flagicons: "/icon-flag",
  ribbon: "/ui-ribbon",

  chat: "/chat",
  videocall: "/video-call",
  audiocall: "/audio-call",
  email: "/email",
  callhistory: "/call-history",
  todo: "/todo",
  wizard: "/form-wizard",
  expiredproduct: "/expired-products",
  lowstock: "/low-stocks",
  categorylist: "/category-list",
  subcategories: "/sub-categories",
  productdetails: "/product-details",

  expenselist: "/expense-list",
  expensecategory: "/expense-category",
  calendar: "/calendar",
  variantattributes: "/variant-attributes",
  qrcode: "/qrcode",

  appearance: "/appearance",
  socialauthendication: "/social-authentication",
  languagesettings: "/language-settings",
  invoicesettings: "/invoice-settings",
  printersettings: "/printer-settings",
  possettings: "/pos-settings",
  customfields: "/custom-fields",
  emailsettings: "/email-settings",
  smssettings: "/sms-gateway",
  otpsettings: "/otp-settings",
  gdbrsettings: "/gdpr-settings",
  paymentgateway: "/payment-gateway-settings",
  banksettingslist: "/bank-settings-list",
  banksettingsgrid: "/bank-settings-grid",
  taxrates: "/tax-rates",
  currencysettings: "/currency-settings",
  storagesettings: "/storage-settings",
  banipaddress: "/ban-ip-address",
  generalsettings: "/general-settings",
  securitysettings: "/security-settings",
  notification: "/notification",
  connectedapps: "/connected-apps",
  systemsettings: "/system-settings",
  companysettings: "/company-settings",
  localizationsettings: "/localization-settings",
  prefixes: "/prefixes",
  preference: "/preference",
  saleslist: "/sales-list",
  invoicereport: "/invoice-report",
  quotationlist: "/quotation-list",
  pos: "/pos",
  attendanceadmin: "attendance-admin",
  payslip: "/payslip",
  holidays: "/holidays",
  customers: "/customers",
  suppliers: "/suppliers",
  storelist: "/store-list",
  managestock: "/manage-stocks",
  //stockadjustment: "/stock-adjustment",
  stocktransfer: "/stock-transfer",
  salesreport: "/sales-report",
  inventoryreport: "/inventory-report",
  supplierreport: "/supplier-report",
  customerreport: "/customer-report",
  expensereport: "/expense-report",
  incomereport: "/income-report",
  taxreport: "/tax-report",
  profitloss: "/profit-loss-report",
  notes: "/notes",
  filemanager: "/file-manager",
  profile: "/profile",
  signin: "/signin",
  signintwo: "/signin-2",
  signinthree: "/signin-3",
  register: "/register",
  registerTwo: "/register-2",
  registerThree: "/register-3",
  forgotPassword: "/forgot-password",
  forgotPasswordTwo: "/forgot-password-2",
  forgotPasswordThree: "/forgot-password-3",
  resetpassword: "/reset-password",
  resetpasswordTwo: "/reset-password-2",
  resetpasswordThree: "/reset-password-3",
  emailverification: "/email-verification",
  emailverificationTwo: "/email-verification-2",
  emailverificationThree: "/email-verification-3",
  twostepverification: "/two-step-verification",
  twostepverificationTwo: "/two-step-verification-2",
  twostepverificationThree: "/two-step-verification-3",
  lockscreen: "/lock-screen",
  error404: "/error-404",
  error500: "/error-500",
  blankpage: "/blank-page",
  comingsoon: "/coming-soon",
  undermaintenance: "/under-maintenance",
  users: "/users",
  rolespermission: "/roles-permissions",
  permissions: "/permissions",
  deleteaccount: "/delete-account",
  employeegrid: "/employees-grid",
  addemployee: "/add-employee",
  editemployee: "/edit-employee",
  leavesadmin: "leaves-admin",
  leavesemployee: "/leaves-employee",
  leavestype: "/leave-types",
  warehouses: "/warehouse",
  coupons: "/coupons",
  payrollList: "/payroll-list",
};
