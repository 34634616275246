import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { useSelector } from "react-redux";
import {
    addEditSales,
    getSalesByTransType,
} from "../../../services/SalesApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import CustomerModal from "../../master/modal/CustomerModal";

const JournalEntryModal = ({ mode, data, handleClose, handleRefresh }) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            deliveryDate: dayjs().format("YYYY-MM-DD"),
            invdate: dayjs().format("YYYY-MM-DD"),
            addition: 0,
            subtraction: 0,
            round: 0,
        },
        mode: "onBlur",
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [isRowAdded, setIsRowAdded] = useState(false);
    const {
        productList,
        unitList,
        settingList,
        customerList,
        formatedproductList,
    } = useSelector((state) => state.usersetting);

    const { userId } = useSelector((state) => state.userauth);
    const { branch } = useSelector((state) => state.branchlist);
    const { formatedLedger } = useSelector((state) => state.ledger);
    // Fetch sales quotations data
    const handleEntryNo = async () => {
        try {
            const response = await getSalesByTransType(branch, 0);
            const salesData = response.data;

            // Group entry numbers by prefix
            const entryGroups = salesData.reduce((groups, data) => {
                const entrynumber = data.entrynumber;
                // Extract the prefix (non-numeric characters)
                const prefix = entrynumber.match(/^[A-Za-z]+/)[0];
                // Extract the numeric part and convert it to an integer
                const entryNum = parseInt(entrynumber.replace(/\D/g, ""), 10);

                if (!groups[prefix]) {
                    groups[prefix] = [];
                }
                groups[prefix].push(entryNum);

                return groups;
            }, {});

            // Get the desired prefix (e.g., "SQ")
            const prefix = "SQ";
            const maxEntryNumber =
                entryGroups[prefix]?.length > 0 ? Math.max(...entryGroups[prefix]) : 0;

            // Increment the counter for the prefix
            const counter = maxEntryNumber + 1;

            // Set the new entry number with the updated counter
            setValue(`entrynumber`, `${prefix}${counter}`);
        } catch (error) {
            console.error(
                "Failed to fetch sales invoices",
                error?.response?.data?.Message || "something went wrong"
            );
        }
    };

    function resetmodal() {
        if (mode === "edit" && data) {
            setValue("terms", data.terms);
            setValue("entrynumber", data.entrynumber);
            console.log(data.salesTransType);
            setValue("salesTransType", data.salesTransType || 0);
            setValue("addition", data.additionalAmt || 0);
            setValue("subtraction", data.discAmt || 0);
            setValue("round", data.roundOff || 0);
            setValue("grossTotal", data.grossTotal || 0);
            setValue("taxTotal", data.taxTotal || 0);
            setValue("netAmt", data.netAmt || 0);
            setValue("paymentType", data.salesPaymentType || 0);
            console.log("data.customer.guid", data.customer.guid);

            setValue("customer", data.customer.guid);
            setValue("invdate", dayjs(data.invdate) || dayjs());
            setValue("deliveryDate", dayjs(data.deliveryDate) || dayjs());
        }
        data?.invSalesDetails?.map((detail, i) => {
            console.log(detail?.product?.guid, "-----------detail");
        });

      
    }
    console.log(getValues().items);
    useEffect(() => {
        resetmodal();
    }, [data, append, remove]);



    useEffect(() => {
        const fetchData = async () => {
            if (mode === "add") {
                handleEntryNo();
                if (!isRowAdded && fields?.length === 0) {
                    handleAddRow();
                    setIsRowAdded(true);
                }
            }

        };

        fetchData();
    }, []);


    const handleAddRow = () => {
        append({
            debit: "0",
            credit: "0",
            narration:'',
            ledger: '',
          
        });
    };
    const handleDebit = () => {
        const total = fields.reduce((sum, e) => sum + +e.debit, 0);
        console.log(total); // or return total if needed
        setValue('totaldebit',total)
    };
    const handleCredit = () => {
        const total = fields.reduce((sum, e) => sum + +e.credit, 0);
        setValue('totalcredit',total)
        console.log(total); // or return total if needed
    };

    return (
        <div>
            <div
                className="modal fade show"
                style={{ display: "block" }}
                tabIndex="-1"
            >
                <div className="modal-dialog add-centered">
                    <div className="modal-content">
                        <div className="page-wrapper p-0 m-0">
                            <div className="content p-0">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>
                                            {" "}
                                            {mode === "edit"
                                                ? "Edit Journal Entry"
                                                : "Add Journal Entry"}
                                        </h4>
                                    </div>
                                    <button type="button" className="close" onClick={handleClose}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <form >
                                            <div className="row">
                                                {/* row1 */}
                                                <div className="col-lg-6 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <label className="form-label">Entry No.</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register("entrynumber")}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12">
                                                    <div className="input-blocks">
                                                        <label>Date</label>
                                                        <div className="input-groupicon calender-input">
                                                            <Calendar className="info-img" />
                                                            <Controller
                                                                control={control}
                                                                name="invdate"
                                                                render={({ field: { onChange, value } }) => (
                                                                    <DatePicker
                                                                        className="datetimepicker"
                                                                        value={value ? dayjs(value) : dayjs()}
                                                                        format="DD-MM-YYYY"
                                                                        onChange={(date) =>
                                                                            onChange(
                                                                                date ? date.format("YYYY-MM-DD") : null
                                                                            )
                                                                        } // Update on change
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                               
                                            </div>

                                            <div className="table-responsive  no-pagination table-container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="sticky-header">
                                                                Ledger
                                                                <span className="text-danger">*</span>
                                                            </th>
                                                            <th className="sticky-header">Debit</th>
                                                            <th className="sticky-header">Credit</th>
                                                            <th className="sticky-header">Narration</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fields?.map((obj, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Controller
                                                                            control={control}
                                                                            name={`items[${index}].ledger`}
                                                                            render={({
                                                                                field: { onChange, value },
                                                                            }) => (
                                                                                <Select
                                                                                    classNamePrefix="react-select"
                                                                                    options={formatedLedger}
                                                                                    value={formatedLedger?.find(
                                                                                        (option) => option.value === value
                                                                                    )}
                                                                                    onChange={(selectedOption) => {
                                                                                        onChange(selectedOption.value);
                                                                                       
                                                                                    }}
                                                                                    styles={{
                                                                                        menu: (provided) => ({
                                                                                            ...provided,
                                                                                            zIndex: 9999,
                                                                                            position: "relative",
                                                                                        }),
                                                                                    }}
                                                                                    required
                                                                                />
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].debit`)}
                                                                            onBlur={handleDebit}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].credit`)}
                                                                            onBlur={handleCredit}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`items[${index}].narration`)}
                                                                            
                                                                        />
                                                                    </td>
                                                                    <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => remove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        <tr>
                                                            
                                                            <td></td>
                                                            <td><input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register(`totaldebit`)}
                                                                            
                                                                        /></td>
                                                            <td>credit</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                                                <button
                                                    className=" btn btn-dark add-icon tab  mt-3 "
                                                    type="button"
                                                    onClick={handleAddRow}
                                                >
                                                    <PlusCircle className="feather-plus-circles" />
                                                </button>
                                            </div>
                                            <div className="row mt-3">
                                                <div className=" col-12 ">
                                                    <div className="input-blocks">
                                                        <label>Remark</label>
                                                        <div className="input-groupicon select-code">
                                                            <input
                                                                type="text"
                                                                className="form-control p-2"

                                                                {...register(`addition`)}
                                                            />
                                                            {errors.addition && (
                                                                <span className="text-danger small mt-1">
                                                                    {errors.addition.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">

                                                <div className="col-lg-12 text-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-cancel add-cancel me-3"
                                                        onClick={resetmodal}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-submit add-sale"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen && (
                <CustomerModal
                    mode="add"
                    data={{}}
                    handleClose={() => setModalOpen(false)}
                    handleRefresh={() => { }}
                />
            )}
        </div>
    );
};
export default JournalEntryModal;

